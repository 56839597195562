import { uniqBy } from "lodash-es";
import moment, { Moment } from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ClassQuery } from "src/api/Class";
import { DiscountsQuery } from "src/api/Discount";
import { FeesQuery } from "src/api/Fee";
import { MembershipOfferingQuery } from "src/api/MembershipOffering";
import { OfferingQuery } from "src/api/Offering";
import { UpdateUserMutation } from "src/api/User";
import AddonModel from "src/models/AddonModel";
import ClassModel from "src/models/ClassModel";
import DiscountModel from "src/models/DiscountModel";
import GroupRegistrationModel, {
  isGroupRegistration,
} from "src/models/GroupRegistrationModel";
import { InvoiceItemModel } from "src/models/InvoiceItemModel";
import InvoiceModel from "src/models/InvoiceModel";
import MembershipModel, { isMembership } from "src/models/MembershipModel";
import MembershipOfferingModel from "src/models/MembershipOfferingModel";
import OfferingModel from "src/models/OfferingModel";
import PrivateSerieModel, {
  isPrivateSerie,
} from "src/models/PrivateSerieModel";
import UserModel from "src/models/UserModel";
import { apiClient, useApi } from "src/stores/Api";
import { calculateCouponsForCharge } from "src/utils/calculateCouponsForCharge";
import calculateCouponsForRegistration from "src/utils/calculateCouponsForRegistration";
import calculateDiscounts from "src/utils/calculateDiscounts";
import calculateFees from "src/utils/calculateFees";
import calculateInvoiceCreditMutations from "src/utils/calculateInvoiceCreditMutations";
import { calculatePricingForMembership } from "src/utils/calculatePricingForMembership";
import getInvoiceItemForGroupRegistration from "src/utils/getInvoiceItemForGroupRegistration";
import { getInvoiceItemForMembership } from "src/utils/getInvoiceItemForMembership";
import { getInvoiceItemForPrivateSerie } from "src/utils/getInvoiceItemForPrivateSerie";
import getSimplifiedInvoiceItems from "src/utils/getSimplifiedInvoiceItems";
import { useMergeState } from "src/utils/useMergeState";
import {
  AppliesToType,
  DepartmentPayload,
  Frequency,
  GroupRegistrationStatus,
  Level,
  PaymentType,
  classItemVariables,
  coupons,
  membershipOfferingVariables,
  offeringVariables,
  studentInfoRequests,
} from "types/code-generator";
import { v4 as uuid } from "uuid";
import { useStore } from "./Store";

export interface WithSessionProps {
  session: Session;
}

interface RegistrationData {
  student: UserModel;
  registrations: Array<
    GroupRegistrationModel | PrivateSerieModel | MembershipModel
  >;
}

export interface PaymentTypeInput {
  type: PaymentType | "None";
  checkNumber?: string;
}

interface CartItem extends AddonModel {
  quantity: number;
  option?: string;
}

type Session = ReturnType<typeof SessionStore>;
type Filters = typeof defaultFilters;

const defaultFilters = {
  accountOwners: "",
  groupOfferings: "",
  memberships: "membershipStatus=active",
  participants: "status=in-progress&status=upcoming",
  privateSeries: "",
  roster: "",
  organizations: "status=Active",
  meetParticipants: "",
  meetEvents: "",
};

const checkoutUrls = ["/find", "/checkout", "/cart"];

function SessionStore() {
  const [groupRegistrations, setGroupRegistrations] = useState<
    GroupRegistrationModel[]
  >([]);
  const [privateSeries, setPrivateSeries] = useState<PrivateSerieModel[]>([]);
  const [memberships, setMemberships] = useState<MembershipModel[]>([]);
  const [students, setStudents] = useState<UserModel[]>([]);
  const [accountOwner, setAccountOwner] = useState<UserModel>(null);
  const [accountOwnerIsParticipant, setAccountOwnerIsParticipant] =
    useState(null);
  const [cart, setCart] = useState<CartItem[]>([]);
  const [additionalInvoiceItems, setAdditionalInvoiceItems] = useState<
    InvoiceItemModel[]
  >([]);
  const [coupons, setCoupons] = useState<coupons["coupons"]>([]);
  const [paymentType, setPaymentType] = useState<PaymentTypeInput>({
    type: PaymentType.Card,
  });
  const [departments, setDepartments] = useState<DepartmentPayload[]>([]);
  const [filters, setFilters] = useMergeState<Filters>(defaultFilters);
  const [returnPathname, setReturnPathname] = useState(null);
  const [ready, setReady] = useState(false);
  const [processedParticipants, setProcessedParticipants] = useState([]);

  const store = useStore();
  const location = useLocation();

  const checkoutQueriesEnabled = Boolean(
    checkoutUrls.find((u) => location.pathname.includes(u))
  );

  const discountsQuery = useApi(DiscountsQuery, {
    skip: !store?.organization?.id || !checkoutQueriesEnabled,
    variables: {
      organizationId: store.organization?.id,
      departmentId: undefined,
    },
  });

  const feesQuery = useApi(FeesQuery, {
    skip: !store?.organization?.id || !checkoutQueriesEnabled,
    variables: {
      where: {
        organizationId: store.organization?.id,
      },
    },
  });

  const fees = feesQuery.fees || [];

  const discounts = discountsQuery.discounts
    ? discountsQuery.discounts.map((d) => new DiscountModel(d))
    : [];

  const sessionStorageKeys = {
    accountOwner,
    accountOwnerIsParticipant,
    additionalInvoiceItems,
    cart,
    filters,
    coupons,
    departments,
    groupRegistrations,
    memberships,
    paymentType,
    privateSeries,
    students,
  };

  useEffect(() => {
    if (!store.organization || !ready) {
      return;
    }

    for (const s in sessionStorageKeys) {
      try {
        window.localStorage.setItem(s, JSON.stringify(sessionStorageKeys[s]));
      } catch (e) {
        console.log("e", e);
      }
    }
  }, Object.values(sessionStorageKeys));

  useEffect(() => {
    checkSessionStorage();
  }, []);

  useEffect(() => {
    if (store.user) {
      processCurrentUser();
    }
  }, [store.user]);

  useEffect(() => {
    if (store.organization) {
      checkPaymentType();

      if (departments.length > 0) {
        updateDepartmentData();
      }
    }
  }, [store.organization]);

  useEffect(() => {
    if (store.user?.isAdminOrAbove && store.organization) {
      store.subscribeToUnreadItems();
    }
  }, [departments, store.organization, store.user]);

  function checkPaymentType() {
    if (store.organization) {
      if (
        !store.organization?.stripeAccountId &&
        !store.organization?.stripeExpressAccountId
      ) {
        setPaymentType({ type: "None" });
      } else {
        setPaymentType({ type: PaymentType.Card });
      }
    }
  }

  function checkSessionStorage() {
    if (accountOwner) {
      return;
    }

    for (const key in sessionStorageKeys) {
      const sessionData = window.localStorage.getItem(key);

      if (!sessionData || sessionData === "undefined") {
        continue;
      }

      let data = JSON.parse(sessionData);

      switch (key) {
        case "accountOwner":
          setAccountOwner(new UserModel(data));
          break;
        case "accountOwnerIsParticipant":
          setAccountOwnerIsParticipant(data);
          break;
        case "additionalInvoiceItems":
          setAdditionalInvoiceItems(data);
          break;
        case "cart":
          setCart(data);
          break;
        case "filters":
          setFilters(data);
          break;
        case "coupons":
          setCoupons(data);
          break;
        case "groupRegistrations":
          setGroupRegistrations(() => {
            const groupRegistrations = data.map(
              (d) => new GroupRegistrationModel(d)
            );

            if (groupRegistrations.length > 0) {
              updateRegistrationSessionData({
                groupRegistrations,
              });
            }

            return groupRegistrations;
          });
          break;
        case "memberships":
          setMemberships(() => {
            const memberships = data.map((d) => new MembershipModel(d));

            if (memberships.length > 0) {
              updateRegistrationSessionData({
                memberships,
              });
            }

            return memberships;
          });

          break;
        case "paymentType":
          setPaymentType(data);
          break;
        case "privateSeries":
          setPrivateSeries(() => {
            const privateSeries = data.map((d) => new PrivateSerieModel(d));

            if (privateSeries.length > 0) {
              updateRegistrationSessionData({
                privateSeries,
              });
            }

            return privateSeries;
          });

          break;
        case "students":
          setStudents(data.map((d) => new UserModel(d)));
          break;
        case "departments":
          // console.log("data", data);

          setDepartments(data);

          if (data.length > 0 && store.organization) {
            updateDepartmentData();
          }

          break;
        default:
          break;
      }
    }

    setReady(true);
  }

  function clearSession() {
    setGroupRegistrations([]);
    setPrivateSeries([]);
    setMemberships([]);
    setStudents([]);
    setCoupons([]);
    setCart([]);
    setAdditionalInvoiceItems([]);
    setAccountOwner(null);
    setPaymentType({ type: PaymentType.Card });
    setFilters(defaultFilters);

    // setDepartments([]);
    window.localStorage.clear();
  }

  function clearCart() {
    setGroupRegistrations([]);
    setPrivateSeries([]);
    setMemberships([]);
    setStudents([]);
    setCoupons([]);
    setCart([]);
    setAdditionalInvoiceItems([]);
    setAccountOwner(null);
    setPaymentType({ type: PaymentType.Card });
  }

  function clearUserSession() {
    setGroupRegistrations([]);
    setPrivateSeries([]);
    setMemberships([]);
    setCoupons([]);
    setCart([]);
    setAdditionalInvoiceItems([]);
    setProcessedParticipants([]);
  }

  function updateDepartmentData() {
    const updatedDepartments = [];

    // console.log("updatedDepartments start", departments);

    for (const d of departments) {
      const department = store.organization?.departments.find(
        (od) => od.id === d.id
      );

      if (department) {
        updatedDepartments.push(department);
      }
    }

    // console.log("updatedDepartments end", updatedDepartments);

    setDepartments(updatedDepartments);
  }

  function processCurrentUser() {
    addCurrentUserToSession();

    if (store.user?.cart && !store.user?.isAdminOrAbove) {
      const cart = store.user?.cart;
      const currentRegistrations = [...groupRegistrations];
      // console.log("currentRegistrations", currentRegistrations);

      if (cart.groupRegistrations && cart.groupRegistrations.length > 0) {
        for (const r of cart.groupRegistrations) {
          // if (!currentRegistrations.find((cr) => cr.class.id === r.class.id)) {
          currentRegistrations.push(new GroupRegistrationModel(r));
          // }
        }
      }

      if (
        cart.students &&
        cart.students.length > 0 &&
        (cart.groupRegistrations?.length > 0 || cart.privateSeries?.length > 0)
      ) {
        setStudents((current) => {
          const newStudents = [...current, ...cart.students]
            // .filter((s) => {
            //   return currentRegistrations.find(
            //     (r) =>
            //       r.student?.id === s.id ||
            //       r.roster?.find((rs) => rs.id === s.id)
            //   );
            // })
            .map((u) => new UserModel(u));

          return uniqBy(newStudents, "id");
        });
      }

      setGroupRegistrations(currentRegistrations);

      updateRegistrationSessionData({
        groupRegistrations: currentRegistrations,
      });
    }

    if (store.organization?.hasDepartments) {
      if (departments.length === 0) {
        const departmentsToSet = store.user?.departments?.filter(
          (d) => d.organization.id === store.organization?.id
        );

        console.log("departmentsToSet", departmentsToSet);
        setDepartments(departmentsToSet);

        if (store.user?.isSalesOrAbove && departments.length === 0) {
          setDepartments(store.organization?.departments.map((d) => d));
        }
      }

      if (store.user?.level === Level.AccountOwner && departments.length > 0) {
        setDepartments([]);
      }
    }

    // if (accountOwner?.id && store.user.id !== accountOwner?.id) {
    //   apiClient(UserQuery, {
    //     variables: {
    //       id: accountOwner.id,
    //     },
    //   }).then((res) => {
    //     if (res.user) {
    //       setAccountOwner(new UserModel(res.user));
    //     }
    //   });
    // }
  }

  function addGroupRegistration(groupRegistration) {
    // console.log("groupRegistration", groupRegistration);

    setGroupRegistrations((current) => {
      let newGroupRegistrations = [...current];

      if (groupRegistration.id) {
        newGroupRegistrations = newGroupRegistrations.filter(
          (r) => r.id !== groupRegistration.id
        );
        newGroupRegistrations.push(
          new GroupRegistrationModel(groupRegistration)
        );
      } else {
        const newRegistration = {
          ...groupRegistration,
          id: uuid(),
        };
        newGroupRegistrations.push(new GroupRegistrationModel(newRegistration));
      }

      return newGroupRegistrations;
    });
  }

  function addPrivateSerie(privateSerie) {
    setPrivateSeries((current) => {
      let newPrivateSeries = [...current];

      if (privateSerie.id) {
        newPrivateSeries = newPrivateSeries.filter(
          (r) => r.id !== privateSerie.id
        );
        newPrivateSeries.push(new PrivateSerieModel(privateSerie));
      } else {
        const newRegistration = {
          ...privateSerie,
          id: uuid(),
        };
        newPrivateSeries.push(new PrivateSerieModel(newRegistration));
      }

      return newPrivateSeries;
    });
  }

  function addMembership(membership) {
    setMemberships((current) => {
      let newMemberships = [...current];

      if (membership.id) {
        newMemberships = newMemberships.filter((r) => r.id !== membership.id);
        newMemberships.push(new MembershipModel(membership));
      } else {
        const newMembership = new MembershipModel({
          ...membership,
          id: uuid(),
        });
        newMemberships.push(newMembership);
      }

      return newMemberships;
    });
  }

  function removeRegistration(registrationId, updateCart = true) {
    const isMembership = memberships.find((m) => m.id === registrationId);
    const isGroup = groupRegistrations.find((m) => m.id === registrationId);
    const isPrivate = privateSeries.find((m) => m.id === registrationId);

    // console.log("removeRegistration groupRegistrations", groupRegistrations);
    // console.log("removeRegistration", registrationId, isGroup);

    if (isGroup) {
      setGroupRegistrations((current) => {
        if (
          updateCart &&
          store.user?.cart &&
          store.user?.cart?.groupRegistrations.length > 0
        ) {
          const registration = current.find((r) => r.id === registrationId);

          const newCart = {
            ...store.user.cart,
            groupRegistrations: store.user.cart.groupRegistrations.filter(
              (r) => r.class.id !== registration.class.id
            ),
          };

          apiClient(UpdateUserMutation, {
            variables: {
              data: {
                cart: newCart,
              },
              where: {
                id: store.user.id,
              },
            },
          }).then((res) => {
            store.setCurrentUser({
              token: undefined,
              user: new UserModel(res.updateUser),
            });
          });
        }

        return current.filter((r) => r.id !== registrationId);
      });
    }

    if (isPrivate) {
      setPrivateSeries((current) => {
        return current.filter((r) => r?.id !== registrationId);
      });
    }

    if (isMembership) {
      setMemberships((current) => {
        const newGroupRegistrations = groupRegistrations.filter((c) => {
          const requiresMembership = !c.class.allowNonMembers;

          console.log("requiresMembership", requiresMembership);

          if (requiresMembership) {
            const offeringHasMembership = c.class.membershipOfferings.find(
              (m) => memberships.find((mm) => mm.offering.id === m.id)
            );

            const userHasMembership = store.user?.memberships?.find(
              (m) => offeringHasMembership?.id === m.offering?.id
            );

            console.log("offeringHasMembership", offeringHasMembership);
            console.log("userHasMembership", userHasMembership);

            if (offeringHasMembership && !userHasMembership) {
              return false;
            }
          }

          return true;
        });

        setGroupRegistrations(newGroupRegistrations);

        const newMemberships = current.filter((r) => r?.id !== registrationId);

        return newMemberships;
      });
    }
  }

  function getStudentsInClass(classId) {
    const registrations = groupRegistrations.filter(
      (r) => r.class.id === classId
    );

    const students = registrations.reduce((acc, r) => {
      let registrationStudents = [];

      // if (r.class.semiPrivate) {
      //   registrationStudents = r.roster ? r.roster : [];
      // } else {
      registrationStudents = r.student ? [r.student] : [];
      // }

      for (const s of registrationStudents) {
        if (!acc.find((accs) => accs.id === s.id)) {
          acc.push(s);
        }
      }

      return acc;
    }, []);

    return students;
  }

  function getStudentsInSeries(offeringId) {
    const registrations = privateSeries.filter(
      (r) => r.offering.id === offeringId
    );
    const students = uniqBy(
      registrations.reduce((acc, r) => acc.concat(r.roster), []),
      "id"
    );

    return students;
  }

  function getRegistrationsForStudent(studentId) {
    const result: Array<
      GroupRegistrationModel | PrivateSerieModel | MembershipModel
    > = [];

    groupRegistrations.forEach((r) => {
      if (r.student && r.student.id === studentId) {
        result.push(r);
      }

      if (r.roster && r.roster.find((rs) => rs.id === studentId)) {
        result.push(r);
      }
    });

    privateSeries.forEach((r) => {
      if (r.roster.find((s) => s.id === studentId)) {
        result.push(r);
      }
    });

    memberships.forEach((r) => {
      if (r.roster.find((s) => s.id === studentId)) {
        result.push(r);
      }
    });

    return result;
  }

  function getRegistrationsByStudent() {
    const result: RegistrationData[] = [];

    for (const student of students.sort((a, b) =>
      a.firstName.localeCompare(b.firstName)
    )) {
      const registrations = getRegistrationsForStudent(student.id);

      if (registrations.length === 0) {
        continue;
      }

      result.push({
        student,
        registrations,
      });
    }

    return result;
  }

  function getStudentsInClasses() {
    const data = [];

    for (const student of students) {
      const classesForStudent = getRegistrationsForStudent(student.id);

      if (classesForStudent.length === 0) {
        continue;
      }

      data.push(student);
    }

    return data;
  }

  function addCurrentUserToSession() {
    let newStudents = [];
    let newAccountOwner = null;

    if (store.user.isSalesOrAbove) {
      return;
    }

    if (
      !store.user.isAdminOrAbove ||
      (store.user.isAdminOrAbove && !accountOwner?.id)
    ) {
      let userStudents = store.user.students;

      if (store.user.primaryAccount) {
        userStudents = store.user.primaryAccount.students;
      }

      if (userStudents?.length > 0) {
        const filteredStudents = students.filter(
          (s) => !userStudents.find((us) => us.id === s.id)
        );
        newStudents = [...filteredStudents, ...userStudents];
      } else {
        newStudents = [...students];
      }

      newAccountOwner = store.user;
    }

    // console.log("newStudents", newStudents);
    // console.log("newAccountOwner", newAccountOwner);

    setStudents((current) => {
      return uniqBy([...current, ...newStudents], "id");
    });

    if (newAccountOwner) {
      setAccountOwner(newAccountOwner);
    }
  }

  async function updateRegistrationSessionData({
    groupRegistrations = [],
    privateSeries = [],
    memberships = [],
  }) {
    const newGroupRegistrations: GroupRegistrationModel[] = [
      ...groupRegistrations,
    ];
    const newPrivateSeries: PrivateSerieModel[] = [...privateSeries];
    const newMemberships: MembershipModel[] = [...memberships];

    for (const gr of newGroupRegistrations) {
      if (!gr.class) {
        removeRegistration(gr.id);
        continue;
      }

      const variables: classItemVariables = {
        id: gr.class.id,
      };

      apiClient(ClassQuery, {
        variables,
      }).then((classData) => {
        const existingRegistrationIndex = newGroupRegistrations.findIndex(
          (sgr) => sgr.id === gr.id
        );

        if (!classData.class || existingRegistrationIndex < 0) {
          return removeRegistration(gr.id);
        }

        // console.log("res.data.class", res.data.class);

        newGroupRegistrations[existingRegistrationIndex].class = new ClassModel(
          classData.class
        );

        addGroupRegistration(newGroupRegistrations[existingRegistrationIndex]);
      });
    }

    for (const ps of newPrivateSeries) {
      if (!ps.offering) {
        removeRegistration(ps.id);
        continue;
      }

      if (!ps.offering.id) {
        continue;
      }

      const variables: offeringVariables = {
        id: ps.offering.id,
      };

      apiClient(OfferingQuery, {
        variables,
      }).then((offeringData) => {
        const existingRegistrationIndex = newPrivateSeries.findIndex(
          (sps) => sps.id === ps.id
        );

        if (!offeringData || existingRegistrationIndex < 0) {
          return removeRegistration(ps.id);
        }

        // console.log("classData.data.class", classData.data.class);

        newPrivateSeries[existingRegistrationIndex].offering =
          new OfferingModel(offeringData.offering);

        addPrivateSerie(newPrivateSeries[existingRegistrationIndex]);
      });
    }

    for (const m of newMemberships) {
      if (!m.offering) {
        removeRegistration(m.id);
        continue;
      }

      const variables: membershipOfferingVariables = {
        id: m.offering.id,
      };

      apiClient(MembershipOfferingQuery, {
        variables,
      }).then((offeringData) => {
        const existingRegistrationIndex = newMemberships.findIndex(
          (sm) => sm.id === m.id
        );

        if (!offeringData.membershipOffering || existingRegistrationIndex < 0) {
          return removeRegistration(m.id);
        }

        // console.log("res.data.class", res.data.class);

        newMemberships[existingRegistrationIndex].offering =
          new MembershipOfferingModel(offeringData.membershipOffering);

        addMembership(newMemberships[existingRegistrationIndex]);
      });
    }
  }

  function addCoupon(coupon: coupons["coupons"][0]) {
    let newCoupons = [...coupons];

    if (coupon.department.allowMultipleCoupons === false) {
      newCoupons = [coupon];
    } else {
      newCoupons.push(coupon);
    }

    const today = moment();

    groupRegistrations.forEach((r, i) => {
      const registrationCoupons = calculateCouponsForRegistration({
        coupons: newCoupons,
        registration: r,
        startDate: today.toDate(),
        organization: store.organization,
      });

      const monthlyCoupons = registrationCoupons
        .map((c) => c.coupon)
        .filter((c) => c.frequency === Frequency.Monthly);

      if (monthlyCoupons.length > 0) {
        addGroupRegistration({
          ...r,
          coupons: monthlyCoupons,
        });
      }
    });

    memberships.forEach((r, i) => {
      const registrationCoupons = calculateCouponsForRegistration({
        coupons: newCoupons,
        registration: r,
        startDate: today.toDate(),
        organization: store.organization,
      });

      const monthlyCoupons = registrationCoupons
        .map((c) => c.coupon)
        .filter((c) => c.frequency === Frequency.Monthly);

      if (monthlyCoupons.length > 0) {
        addMembership({
          ...r,
          coupons: monthlyCoupons,
        });
      }
    });

    setCoupons(newCoupons);
  }

  function removeCoupon(couponId: string) {
    const newCoupons = coupons.filter((i) => i.id !== couponId);
    const newGroupRegistrations = [...groupRegistrations];

    newGroupRegistrations.forEach((r, i) => {
      if (r.coupons) {
        const coupons = r.coupons.filter((i) => i.id !== couponId);
        addGroupRegistration({
          ...r,
          coupons,
        });
      }
    });

    setCoupons(newCoupons);
    setGroupRegistrations(newGroupRegistrations);
  }

  function getCurrentInvoice(
    args: {
      membership: MembershipModel;
    } = null
  ) {
    const invoiceDate = moment();
    const invoiceItems = getInvoiceItems({
      date: invoiceDate,
      membership: args?.membership,
    });

    const simplifiedInvoiceItems = getSimplifiedInvoiceItems(invoiceItems);

    const invoice = new InvoiceModel({
      invoiceItems: simplifiedInvoiceItems,
      invoiceDate: invoiceDate.toDate(),
    });

    return invoice;
  }

  function getUpcomingInvoice() {
    const invoiceDate = moment().add(1, "month").startOf("month");
    const invoiceItems = getInvoiceItems({ date: invoiceDate });
    const simplifiedInvoiceItems = getSimplifiedInvoiceItems(invoiceItems);

    const invoice = new InvoiceModel({
      invoiceItems: simplifiedInvoiceItems,
      invoiceDate: invoiceDate.toDate(),
    });

    return invoice;
  }

  function getInvoiceItems(args: {
    date: Moment;
    membership?: MembershipModel;
    registrations?: Array<
      GroupRegistrationModel | PrivateSerieModel | MembershipModel
    >;
  }) {
    let invoiceItems = [];
    let registrations = [
      ...groupRegistrations,
      ...privateSeries,
      ...memberships,
    ];

    if (args.registrations) {
      registrations = args.registrations;
    }

    const today = moment();

    if (!store.organization) {
      return [];
    }

    // console.log("store", store.organization);

    const enabledDiscounts = calculateDiscounts({
      discounts,
      students: students,
      registrations,
      startDate: args.date.toDate(),
      organization: store.organization,
    });

    // console.log("discounts", discounts);

    registrations
      .filter(isGroupRegistration)
      .forEach((r: GroupRegistrationModel, i) => {
        const cartMemberships = memberships.filter(
          (m) => m.offering?.autoEnroll
        );

        const invoiceItem = getInvoiceItemForGroupRegistration({
          registration: r,
          registrations: groupRegistrations,
          startDate: args.date.toDate(),
          discounts: enabledDiscounts,
          coupons: coupons,
          organization: store.organization,
          index: i,
          atRegistration: true,
          user: new UserModel({
            ...accountOwner,
            memberships: [
              ...(accountOwner?.memberships || []),
              ...cartMemberships,
            ],
          }),
        });

        invoiceItems.push(invoiceItem);

        if (r.class.charges) {
          for (const c of r.class.charges) {
            if (
              c.appliesTo === AppliesToType.Waitlist &&
              r.status !== GroupRegistrationStatus.Waitlist
            ) {
              continue;
            }

            if (
              c.appliesTo === AppliesToType.Approved &&
              r.status !== GroupRegistrationStatus.Approved &&
              r.status !== GroupRegistrationStatus.Pending
            ) {
              continue;
            }

            const chargeCoupons = calculateCouponsForCharge({
              coupons,
              registration: r,
              startDate: args.date.toDate(),
              organization: store.organization,
              chargeAmount: c.amount,
            });

            invoiceItems.push({
              id: uuid(),
              amount: c.amount,
              description: c.description,
              billingCategory: {
                id: c.billingCategory?.id,
                title: c.billingCategory?.title,
              },
              charge: {
                ...c,
              },
              coupons: chargeCoupons,
              notRemoveable: true,
            });
          }
        }

        if (r.class.credits) {
          for (const c of r.class.credits) {
            invoiceItems.push({
              id: uuid(),
              amount: c.amount,
              description: c.description,
              billingCategory: {
                id: c.billingCategory.id,
                title: c.billingCategory.title,
              },
              credit: {
                ...c,
              },
              notRemoveable: true,
            });
          }
        }
      });

    // console.log("invoiceItems", invoiceItems);

    registrations.filter(isPrivateSerie).forEach((r: PrivateSerieModel, i) => {
      const invoiceItem = getInvoiceItemForPrivateSerie({
        registration: r,
        startDate: args.date.toDate(),
        coupons,
        organization: store.organization,
      });

      invoiceItems.push(invoiceItem);
    });

    registrations.filter(isMembership).forEach((r: MembershipModel, i) => {
      const invoiceItem = getInvoiceItemForMembership({
        membership: r,
        startDate: args.date.toDate(),
        coupons,
      });

      if (args.membership) {
        const { price: currentPrice } = calculatePricingForMembership({
          membership: args.membership,
          startDate: args.date.toDate(),
        });

        const { price: newPrice } = calculatePricingForMembership({
          membership: memberships.find((m) => m.id === args.membership.id),
          startDate: args.date.toDate(),
        });

        let price = 0;

        if (newPrice > currentPrice) {
          price = newPrice - currentPrice;
        }

        invoiceItem.amount = price;
      }

      invoiceItems.push(invoiceItem);
    });

    // console.log("invoiceItems", invoiceItems);

    additionalInvoiceItems.forEach((i) => {
      // if (i.charge || i.credit) {
      invoiceItems.push(i);
      // }
    });

    // console.log("cart", cart);

    cart.forEach((a) => {
      const invoiceItem = {
        id: uuid(),
        amount: a.price * a.quantity,
        description: a.title,
        addon: a,
        quantity: a.quantity,
      };

      if (a.option) {
        invoiceItem.description = a.option;
      }

      invoiceItems.push(invoiceItem);
    });

    if (today.isSame(args.date, "day")) {
      if (
        accountOwner &&
        accountOwner.id === store.user?.id &&
        store.billingSummary?.balance > 0
      ) {
        invoiceItems.push({
          id: uuid(),
          description: "Outstanding Account Balance",
          amount: store.billingSummary.balance,
        });
      }
    }

    // const additionalFees = getOneTimeAdditionalFees({
    //   groupRegistrations: groupRegistrations,
    //   privateSeries: privateSeries,
    // });

    // if (additionalFees.amount > 0) {
    //   invoiceItems.push(additionalFees);
    // }

    if (fees.length > 0) {
      const feesToAdd = calculateFees({
        invoiceItems: invoiceItems.slice(),
        fees,
      });

      for (const fee of feesToAdd) {
        invoiceItems.push(fee);
      }
    }

    if (accountOwner && accountOwner.credits) {
      const currentInvoice = new InvoiceModel({
        invoiceItems,
      });

      const availableCredits = accountOwner.credits
        .filter((c) => c.amount > 0)
        .reduce((acc, c) => {
          return acc + c.amount;
        }, 0);

      if (availableCredits > 0) {
        const invoiceTotal = currentInvoice.getTotals().totalOwed;

        if (invoiceTotal > 0) {
          let creditAmount = availableCredits;

          if (creditAmount > invoiceTotal) {
            creditAmount = invoiceTotal;
          }

          const accountCreditsInvoiceItem = {
            id: uuid(),
            amount: creditAmount,
            description: "Account Credits",
            omit: true,
            credit: {
              amount: creditAmount,
              description: "Account Credits",
            },
          };

          invoiceItems.push(accountCreditsInvoiceItem);
        }
      }
    }

    if (invoiceItems.find((ii) => ii.credit)) {
      const creditInvoiceItems = invoiceItems.filter((ii) => ii.credit);
      const newCredits = invoiceItems.reduce((acc, ii) => {
        if (ii.credit) {
          acc.push(ii.credit);
        }
        return acc;
      }, []);

      const newInvoiceData = calculateInvoiceCreditMutations(
        new InvoiceModel({
          invoiceItems: invoiceItems.filter((i) => !i.credit),
          user: {
            ...store.user,
            credits: newCredits,
          },
        })
      );

      invoiceItems = newInvoiceData.updatedInvoice.invoiceItems;
      invoiceItems = invoiceItems.concat(creditInvoiceItems);

      // console.log("newInvoiceData", newInvoiceData);
    }

    return invoiceItems as InvoiceItemModel[];
  }

  function updateAccountOwner(values) {
    if (values === null) {
      setAccountOwner(null);
      return;
    }

    setAccountOwner((current) => {
      return {
        ...current,
        ...values,
      };
    });
  }

  function addCart(cartItem: CartItem) {
    setCart((current) => {
      let newCart = [...current];
      let newCartItem = { ...cartItem };
      const existingCartItem = newCart.find((c) => {
        if (cartItem.option) {
          return c.id === cartItem.id && cartItem.option === c.option;
        }

        return c.id === cartItem.id;
      });

      if (existingCartItem) {
        newCartItem.quantity += existingCartItem.quantity;
      }

      newCart = newCart.filter((c) => {
        if (cartItem.option) {
          return (
            c.id !== cartItem.id ||
            (c.id === cartItem.id && cartItem.option !== c.option)
          );
        }

        return c.id !== newCartItem.id;
      });

      newCart.push(newCartItem);

      return newCart;
    });
  }

  function removeCart(cartItem: CartItem) {
    setCart((current) => {
      let newCart = [...current];
      let newCartItem = { ...cartItem };
      const existingCartItem = newCart.find((c) => {
        if (cartItem.option) {
          return c.id === cartItem.id && cartItem.option === c.option;
        }

        return c.id === cartItem.id;
      });

      if (cartItem.quantity) {
        newCartItem.quantity = existingCartItem.quantity - 1;
      }

      newCart = newCart.filter((c) => {
        return (
          c.id !== cartItem.id ||
          (c.id === cartItem.id && cartItem.option !== c.option)
        );

        return c.id !== newCartItem.id;
      });

      if (cartItem.quantity && newCartItem.quantity > 0) {
        newCart.push(newCartItem);
      }

      return newCart;
    });
  }

  function needsStudentInformation() {
    return (
      students.length > 0 && Boolean(students.find((s) => s.id.includes("-")))
    );
  }

  function needsPaymentInformation() {
    return (
      (!accountOwner ||
        !accountOwner.stripeCustomerId ||
        !accountOwner.stripeToken ||
        !accountOwner.stripeToken.id) &&
      !store.isAdminArea
    );
  }

  function needsAccountOwnerInformation() {
    return !accountOwner || !accountOwner.id;
  }

  function getStudentInfoRequests(): studentInfoRequests["studentInfoRequests"] {
    const registrations = groupRegistrations.filter(
      (r) => r.class?.studentInfoRequests?.length > 0
    );

    const membershipsData = memberships.filter(
      (r) => r.offering?.studentInfoRequests?.length > 0
    );

    const studentInfoRequests = [];

    for (const r of registrations) {
      r.class?.studentInfoRequests.forEach((sir) => {
        if (!studentInfoRequests.find((accsir) => accsir.id === sir.id)) {
          studentInfoRequests.push(sir);
        }
      });
    }

    for (const m of membershipsData) {
      m.offering?.studentInfoRequests.forEach((sir) => {
        if (!studentInfoRequests.find((accsir) => accsir.id === sir.id)) {
          studentInfoRequests.push(sir);
        }
      });
    }

    return studentInfoRequests;
  }

  function askForPaymentInformation() {
    let result = true;
    const waitlistedRegistrations = groupRegistrations.filter(
      (r) => r.status === GroupRegistrationStatus.Waitlist && r.class.price > 0
    );
    const monthlyRegistrations = groupRegistrations.filter(
      (r) => r.class.paymentFrequency === Frequency.Monthly && r.class.price > 0
    );
    const recurringMemberships = memberships.filter(
      (m) =>
        m.offering.paymentFrequency === Frequency.Monthly &&
        (m.offering.price > 0 ||
          m.offering.priceLevels.some((pl) => pl.price > 0))
    );
    const currentInvoiceData = getCurrentInvoice();

    if (
      currentInvoiceData.getTotals().totalOwed === 0 &&
      waitlistedRegistrations.length === 0 &&
      monthlyRegistrations.length === 0 &&
      recurringMemberships.length === 0
    ) {
      result = false;
    }

    return result;
  }

  // console.log("departments", departments);

  return {
    accountOwner,
    accountOwnerIsParticipant,
    addCart,
    addCoupon,
    addCurrentUserToSession,
    addGroupRegistration,
    additionalInvoiceItems,
    addMembership,
    addPrivateSerie,
    askForPaymentInformation,
    cart,
    clearCart,
    clearSession,
    clearUserSession,
    coupons,
    departments,
    filters,
    getCurrentInvoice,
    getInvoiceItems,
    getRegistrationsByStudent,
    getRegistrationsForStudent,
    getStudentInfoRequests,
    getStudentsInClass,
    getStudentsInClasses,
    getStudentsInSeries,
    getUpcomingInvoice,
    groupRegistrations,
    memberships,
    needsAccountOwnerInformation,
    needsPaymentInformation,
    needsStudentInformation,
    paymentType,
    privateSeries,
    processedParticipants,
    removeCart,
    removeCoupon,
    removeRegistration,
    returnPathname,
    setAccountOwnerIsParticipant,
    setAdditionalInvoiceItems,
    setCart,
    setCoupons,
    setDepartments,
    setFilters,
    setGroupRegistrations,
    setMemberships,
    setPaymentType,
    setPrivateSeries,
    setProcessedParticipants,
    setReturnPathname,
    setStudents,
    students,
    updateAccountOwner,
    updateRegistrationSessionData,
  };
}

const SessionContext = React.createContext<Session | null>(null);

export const SessionProvider = (props) => {
  const session = SessionStore();

  return (
    <SessionContext.Provider value={session}>
      {props.children}
    </SessionContext.Provider>
  );
};

export function withSession(Component) {
  return function WithSessionComponent(props) {
    const session = useSession();

    return <Component {...props} session={session} />;
  };
}

export const useSession = () => {
  const session = useContext(SessionContext);
  return session;
};
