import { gql } from "graphql-tag";
import {
  createDepartment,
  createDepartmentVariables,
  deleteDepartment,
  deleteDepartmentVariables,
  departments,
  departmentsVariables,
  fullDepartment,
  fullDepartmentVariables,
  updateDepartment,
  updateDepartmentVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";
import { TagPayload } from "./Tag";

export const DepartmentPayload = gql`
  fragment DepartmentPayload on Department {
    id
    active
    allowMultipleCoupons
    annualFeeAmount
    annualFeeAtBeginningOfYear
    annualFeeBillingCategory {
      id
      title
    }
    annualFeeCanBeAppliedAtOfferingLevel
    annualFeeMax
    annualFeeType
    contactName
    createdAt
    default
    email
    filters
    organization {
      id
    }
    programs {
      id
      title
    }
    phone
    settingOnboards
    showProgramDescription
    tags(orderBy: { order: asc }) {
      ...TagPayload
    }
    stripeExpressAccountId
    title
    updatedAt
    users {
      id
      level
      firstName
      lastName
    }
  }
  ${TagPayload}
`;

export const UpdateDepartmentMutation = gql`
  mutation updateDepartment(
    $data: DepartmentCreateInput!
    $where: UniqueInput!
  ) {
    updateDepartment(data: $data, where: $where) {
      ...DepartmentPayload
    }
  }
  ${DepartmentPayload}
` as TypedDocumentNode<updateDepartment, updateDepartmentVariables>;

export const DeleteDepartmentMutation = gql`
  mutation deleteDepartment($id: String!) {
    deleteDepartment(where: { id: $id }) {
      id
    }
  }
` as TypedDocumentNode<deleteDepartment, deleteDepartmentVariables>;

export const CreateDepartmentMutation = gql`
  mutation createDepartment($data: DepartmentCreateInput!) {
    createDepartment(data: $data) {
      ...DepartmentPayload
    }
  }
  ${DepartmentPayload}
` as TypedDocumentNode<createDepartment, createDepartmentVariables>;

export const DepartmentsQuery = gql`
  query departments($organizationId: String!) {
    departments(where: { organizationId: $organizationId }) {
      ...DepartmentPayload
    }
  }
  ${DepartmentPayload}
` as TypedDocumentNode<departments, departmentsVariables>;

export const FullDepartmentQuery = gql`
  query fullDepartment($id: String!) {
    department(where: { id: $id }) {
      ...DepartmentPayload
      classes {
        id
      }
      membershipOfferings {
        id
      }
      offerings {
        id
      }
      users {
        id
        firstName
        lastName
        level
      }
    }
  }
  ${DepartmentPayload}
` as TypedDocumentNode<fullDepartment, fullDepartmentVariables>;
