import { gql } from "graphql-tag";
import {
  captynUser,
  captynUserVariables,
  captynUsers,
  captynUsersVariables,
  createUser,
  createUserVariables,
  deleteUser,
  deleteUserVariables,
  exportCaptynUsers,
  exportCaptynUsersVariables,
  exportUsers,
  exportUsersVariables,
  filteredUsers,
  filteredUsersCounts,
  filteredUsersCountsVariables,
  filteredUsersVariables,
  manageJobCredits,
  manageJobCreditsVariables,
  me,
  meVariables,
  mergeAccount,
  mergeAccountVariables,
  mergeParticipant,
  mergeParticipantVariables,
  salesUsers,
  salesUsersVariables,
  unattachedProfiles,
  unattachedProfilesVariables,
  updateUser,
  updateUserVariables,
  user,
  userBillingSummary,
  userBillingSummaryVariables,
  userEmail,
  userEmailVariables,
  userInstructors,
  userInstructorsVariables,
  userVariables,
  usersCaptynEmail,
  usersCaptynEmailVariables,
  usersOrganizationEmail,
  usersOrganizationEmailVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";

export const UserPayloadFragment = gql`
  fragment UserPayload on User {
    active
    accountOwner {
      id
      email
      firstName
      groupRegistrations {
        id
        roster {
          id
        }
        status
        student {
          id
        }
        finalDate
      }
      lastName
      linkedAccounts {
        id
        firstName
        lastName
        email
        phone
      }
      phone
      privateSeries {
        id
        status
      }
      stripeCustomerId
      stripeToken
      students {
        id
        firstName
        lastName
        birthDate
        gender
        avatar {
          id
          url
        }
      }
    }
    address1
    address2
    allowAssignment
    allowMarketing
    avatar {
      id
      url
    }
    balance
    bio
    birthDate
    cart
    city
    createdAt
    credits {
      id
      amount
      billingCategory {
        id
        title
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      issuedAmount
      description
      recurringCredit {
        id
        createdBy {
          id
          firstName
          lastName
        }
      }
    }
    customNotes
    customerServiceItems {
      id
      type
    }
    departments {
      id
      email
      title
      active
      organization {
        id
      }
      programs {
        id
      }
      tags {
        id
        title
      }
    }
    email
    emergencyContactName
    emergencyContactPhone
    evaluations {
      id
    }
    firstName
    futureInvoiceBalance
    gender
    groupRegistrations(where: { status: { not: Error } }) {
      id
      status
      student {
        id
      }
      class {
        id
        agreements {
          id
        }
        program {
          id
          department {
            id
          }
        }
        studentInfoRequests {
          id
        }
        type
      }
      studentInfoRequestResponses {
        additionalResponse
        description
        studentInfoRequestId
      }
    }
    healthQuestionResponses {
      id
      response
      healthQuestion {
        id
        slug
      }
    }
    hideInFilters
    jobCreditBalance
    memberships(where: { status: { not: Error } }) {
      id
      offeringId
      offering {
        id
        agreements {
          id
        }
        participantType
        type
      }
      roster {
        id
        firstName
      }
      status
      studentInfoRequestResponses {
        additionalResponse
        description
        studentInfoRequestId
      }
    }
    membershipsRoster(where: { status: { not: Error } }) {
      id
    }
    permissions
    primaryAccount {
      id
      firstName
      email
      groupRegistrations {
        id
        status
        class {
          id
          agreements {
            id
          }
          studentInfoRequests {
            id
          }
        }
        student {
          id
        }
      }
      lastName
      memberships {
        id
        offeringId
        offering {
          id
          participantType
          type
          agreements {
            id
          }
        }
        roster {
          id
          firstName
        }
        status
        studentInfoRequestResponses {
          additionalResponse
          description
          studentInfoRequestId
        }
      }
      phone
      privateSeries {
        id
        status
        offering {
          id
          agreements {
            id
          }
        }
      }
      stripeCustomerId
      stripeToken
      students {
        id
        birthDate
        firstName
        gender
        lastName
        avatar {
          id
          url
        }
      }
      userAgreements {
        id
        agreement {
          id
        }
        createdAt
      }
    }
    studentGroupRegistrations(where: { status: { not: Error } }) {
      id
      class {
        id
        program {
          id
          department {
            id
          }
        }
      }
      status
    }
    healthConcerns
    id
    invoices {
      id
      status
      createdAt
      invoiceDate
    }
    lastName
    level
    linkedAccounts {
      id
      firstName
      lastName
      email
      phone
    }
    memberSince
    note
    organization {
      id
    }
    phone
    privateSeries {
      id
      status
      offering {
        id
        agreements {
          id
        }
      }
    }
    profile {
      id
      profiles {
        id
        level
        organization {
          id
          color
          logo {
            url
          }
          subdomain
          title
        }
      }
      settings {
        id
        type
        value
      }
    }
    profiles {
      id
      lastAccess
      level
      organization {
        id
        logo {
          id
          url
        }
        subdomain
        title
      }
      stripeCustomerId
      stripeToken
      permissions
    }
    recurringCharges {
      id
      amount
      description
      finalDate
    }
    recurringCredits {
      id
      amount
      description
      finalDate
    }
    settings {
      id
      type
      value
      department {
        id
      }
    }
    state
    stripeCustomerId
    stripeToken
    students {
      accountOwner {
        id
      }
      birthDate
      emergencyContactName
      emergencyContactPhone
      firstName
      gender
      groupRegistrations {
        id
        status
      }
      healthConcerns
      healthQuestionResponses {
        id
        response
        healthQuestion {
          id
          question
          slug
        }
      }
      id
      lastName
      privateSeries {
        id
        status
      }
      profile {
        id
      }
      profiles {
        id
      }
      avatar {
        id
        url
      }
    }
    usaSwimmingMemberId
    usaSwimmingMember
    userAgreements {
      id
      agreement {
        id
      }
      createdAt
    }
    zip
  }
`;

export const UpdateUserMutation = gql`
  mutation updateUser($data: UserCreateInput!, $where: UniqueInput!) {
    updateUser(data: $data, where: $where) {
      ...UserPayload
    }
  }
  ${UserPayloadFragment}
` as TypedDocumentNode<updateUser, updateUserVariables>;

export const DeleteUserMutation = gql`
  mutation deleteUser($id: String!) {
    deleteUser(where: { id: $id }) {
      id
    }
  }
` as TypedDocumentNode<deleteUser, deleteUserVariables>;

export const CreateUserMutation = gql`
  mutation createUser($data: UserCreateInput!) {
    createUser(data: $data) {
      ...UserPayload
    }
  }
  ${UserPayloadFragment}
` as TypedDocumentNode<createUser, createUserVariables>;

export const InstructorsQuery = gql`
  query userInstructors($organizationId: String!, $filter: String) {
    users(
      where: {
        organizationId: $organizationId
        level: { in: [Instructor, Admin] }
      }
      filter: $filter
      orderBy: { lastName: asc }
    ) {
      id
      allowAssignment
      createdAt
      departments {
        id
        programs {
          id
        }
      }
      email
      firstName
      lastAccess
      lastName
      level
      permissions
    }
  }
` as TypedDocumentNode<userInstructors, userInstructorsVariables>;

export const FilteredUsersQuery = gql`
  query filteredUsers(
    $where: UserWhereInput!
    $orderBy: UserOrderByInput
    $filter: String
    $skip: Int
    $take: Int
  ) {
    users(
      where: $where
      orderBy: $orderBy
      filter: $filter
      take: $take
      skip: $skip
    ) {
      id
      phone
      email
      firstName
      lastName
      birthDate
      level
      gender
      avatar {
        id
        url
      }
      accountOwner {
        id
        active
        email
        phone
        firstName
        lastName
        birthDate
        avatar {
          id
          url
        }
        note
      }
      students {
        id
        active
        phone
        firstName
        lastName
        birthDate
        level
        gender
        avatar {
          id
          url
        }
        healthQuestionResponses {
          id
          response
          healthQuestion {
            id
            slug
          }
        }
      }
      healthQuestionResponses {
        id
        response
        healthQuestion {
          id
          slug
        }
      }
      note
    }
  }
` as TypedDocumentNode<filteredUsers, filteredUsersVariables>;

export const FilteredUsersCountsQuery = gql`
  query filteredUsersCounts($where: UserWhereInput!, $filter: String) {
    usersCounts(where: $where, filter: $filter) {
      total
      groupOfferings {
        total
        active
        pending
        waitlist
        pendingCancel
        completed
        canceled
        moved
        declined
      }
      membershipOfferings {
        total
        active
        pending
        waitlist
        pendingCancel
        completed
      }
      privateOfferings {
        total
        active
        pending
        posted
        paused
        inactive
      }
      financial {
        balance {
          amount
          accounts
        }
        expiredCard {
          amount
          accounts
        }
        noCard {
          amount
          accounts
        }
        creditBalance {
          amount
          accounts
        }
        cardExpiring
        jobCredits {
          amount
          accounts
        }
        monthlyCharges {
          amount
          accounts
        }
        monthlyCredits {
          amount
          accounts
        }
        activeCoupons {
          amount
          accounts
        }
        tokens {
          amount
          accounts
        }
        cart {
          amount
          accounts
        }
        futureInvoiceBalance {
          date
          accounts
        }
      }
    }
  }
` as TypedDocumentNode<filteredUsersCounts, filteredUsersCountsVariables>;

export const StaffQuery = gql`
  query staff($organizationId: String!, $active: Boolean) {
    users(
      where: {
        organizationId: $organizationId
        level: { in: [Admin, Instructor] }
        active: $active
      }
      orderBy: { lastName: asc }
    ) {
      id
      level
      firstName
      lastName
    }
  }
`;

export const UserQuery = gql`
  query user($id: String!, $organizationId: String) {
    user(where: { id: $id, organizationId: $organizationId }) {
      ...UserPayload
    }
  }
  ${UserPayloadFragment}
` as TypedDocumentNode<user, userVariables>;

export const CaptynUsersQuery = gql`
  query captynUsers(
    $where: CaptynUsersInput
    $orderBy: UserOrderByInput
    $skip: Int
    $take: Int
  ) {
    captynUsers(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      createdAt
      firstName
      lastAccess
      lastName
      email
      level
      updatedAt
      profiles {
        id
        createdAt
        email
        firstName
        lastAccess
        lastName
        level
        organization {
          id
          subdomain
          title
        }
        updatedAt
      }
    }
  }
` as TypedDocumentNode<captynUsers, captynUsersVariables>;

export const CaptynUserQuery = gql`
  query captynUser($id: String!) {
    user(where: { id: $id }) {
      id
      createdAt
      firstName
      lastName
      email
      level
      phone
      profiles {
        id
        createdAt
        email
        firstName
        lastName
        level
        organization {
          id
          subdomain
          title
        }
        updatedAt
      }
      updatedAt
    }
  }
` as TypedDocumentNode<captynUser, captynUserVariables>;

export const UsersOrganizationEmailQuery = gql`
  query usersOrganizationEmail($email: String!, $organizationId: String!) {
    users(
      where: {
        OR: [
          { email: { equals: $email }, organizationId: $organizationId }
          { email: { equals: $email }, level: { in: [SuperAdmin, Sales] } }
        ]
      }
      take: 1
    ) {
      id
    }
  }
` as TypedDocumentNode<usersOrganizationEmail, usersOrganizationEmailVariables>;

export const UsersCaptynEmailQuery = gql`
  query usersCaptynEmail($email: String!) {
    users(
      where: { email: { equals: $email }, level: { in: [Captyn, SuperAdmin] } }
      take: 1
    ) {
      id
    }
  }
` as TypedDocumentNode<usersCaptynEmail, usersCaptynEmailVariables>;

export const UserEmailQuery = gql`
  query userEmail($email: String!, $organizationId: String) {
    userEmail(email: $email, organizationId: $organizationId) {
      id
      hasPassword
      hasPhone
      multiFactor
    }
  }
` as TypedDocumentNode<userEmail, userEmailVariables>;

export const UnattachedProfilesQuery = gql`
  query unattachedProfiles($email: String!) {
    users(
      where: {
        email: { equals: $email }
        level: { notIn: [Captyn] }
        students: { some: { profileId: null } }
      }
    ) {
      ...UserPayload
    }
  }
  ${UserPayloadFragment}
` as TypedDocumentNode<unattachedProfiles, unattachedProfilesVariables>;

export const CurrentUserQuery = gql`
  query me {
    me {
      token
      user {
        ...UserPayload
      }
    }
  }
  ${UserPayloadFragment}
` as TypedDocumentNode<me, meVariables>;

export const ExportUsersMutation = gql`
  mutation exportUsers($where: UserWhereInput!, $filter: String) {
    exportUsers(where: $where, filter: $filter)
  }
` as TypedDocumentNode<exportUsers, exportUsersVariables>;

export const ExportCaptynUsersMutation = gql`
  mutation exportCaptynUsers($where: CaptynUsersInput!) {
    exportCaptynUsers(where: $where)
  }
` as TypedDocumentNode<exportCaptynUsers, exportCaptynUsersVariables>;

export const SalesUsersQuery = gql`
  query salesUsers {
    users(
      where: { level: { in: [Sales, SuperAdmin] } }
      orderBy: { lastName: asc }
    ) {
      id
      firstName
      lastName
      level
      email
    }
  }
` as TypedDocumentNode<salesUsers, salesUsersVariables>;

export const UserBillingSummaryQuery = gql`
  query userBillingSummary($id: ID!) {
    userBillingSummary(id: $id) {
      balance
      totalBillable
      totalPaid
      totalRefunded
      totalDiscounts
      totalCoupons
      totalCredits
      futureInvoice
      invoicedThisMonth
    }
  }
` as TypedDocumentNode<userBillingSummary, userBillingSummaryVariables>;

export const SendEnrollmentSummaryMutation = gql`
  mutation sendEnrollmentSummary(
    $userIds: [ID]
    $organizationId: ID
    $filter: String
  ) {
    sendEnrollmentSummary(
      userIds: $userIds
      organizationId: $organizationId
      filter: $filter
    ) {
      success
    }
  }
`;

export const MergeAccountMutation = gql`
  mutation mergeAccount(
    $from: ID!
    $to: ID!
    $link: Boolean
    $changePrimary: Boolean
    $sendEmail: Boolean
  ) {
    mergeAccount(
      from: $from
      to: $to
      link: $link
      changePrimary: $changePrimary
      sendEmail: $sendEmail
    ) {
      id
    }
  }
` as TypedDocumentNode<mergeAccount, mergeAccountVariables>;

export const MergeParticipantMutation = gql`
  mutation mergeParticipant($from: ID!, $to: ID!, $sendEmail: Boolean) {
    mergeParticipant(from: $from, to: $to, sendEmail: $sendEmail) {
      id
    }
  }
` as TypedDocumentNode<mergeParticipant, mergeParticipantVariables>;

export const ManageJobCreditsMutation = gql`
  mutation manageJobCredits($data: ManageJobCreditsInput!) {
    manageJobCredits(data: $data)
  }
` as TypedDocumentNode<manageJobCredits, manageJobCreditsVariables>;
